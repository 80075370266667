import { EyeIcon } from "@heroicons/react/24/outline";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { CreateCommentParams, getter } from "../api";
import * as types from "../api/types";
import { GroupAutocompleteResult } from "../api/types";
import CommentInput from "./CommentInput";
import ErrorModal from "./ErrorModal";
import Spinner from "./Spinner";
import { parseAxiosError } from "./utils";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";
import VisibilityField from "./VisibilityField";

interface NewCommentParams {
  text: string;
  visibility: "public" | "groups" | undefined;
  groups: GroupAutocompleteResult[] | undefined;
  users: GroupAutocompleteResult[] | undefined;
}

interface CommentBoxProps {
  publishComment: (data: CreateCommentParams) => Promise<void>;
  showVisibility?: boolean;
  fontSize?: string;
  hideVisibilityLabel?: boolean;
}

export default function CommentBox({
  publishComment,
  showVisibility = true,
  fontSize = "text-xs",
  hideVisibilityLabel = false,
}: CommentBoxProps) {
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { data: groups } = useSWR<types.GroupAutocompleteResult[]>(
    "/api/users/autocomplete/groups",
    getter,
    { revalidateOnFocus: false },
  );

  const { data: selectedGroup } = useSWR<any>(GLOBAL_GROUP_KEY);

  const initialValues: NewCommentParams = {
    text: "",
    visibility: "groups",
    groups: [],
    users: [],
  };

  useEffect(() => {
    if (selectedGroup) {
      initialValues.groups = Array.of(selectedGroup);
    }
    // eslint-disable-next-line
  }, [selectedGroup]);

  const handlePublishComment = (
    values: NewCommentParams,
    { resetForm }: FormikHelpers<NewCommentParams>,
  ) => {
    publishComment({
      text: values.text,
      visibility: values.visibility,
      groups: values.groups?.map((option) => option.value),
      users: values.users?.map((option) => option.value),
    })
      .then((response) => {
        resetForm();
      })
      .catch((error) => {
        setErrorModal(true);
        setErrorMessage(parseAxiosError(error));
        resetForm();
      });
  };

  const [displayVisibility, setDisplayVisibility] = useState<boolean>(false);

  if (groups) {
    return (
      <>
        <ErrorModal
          open={errorModal}
          setOpen={setErrorModal}
          errorMessage={errorMessage}
        />
        <Formik initialValues={initialValues} onSubmit={handlePublishComment}>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div
                className={`mt-5 rounded-xl border border-gray-300 ${fontSize}`}
              >
                <div className="w-full p-3">
                  <div className="relative flex w-full text-xs">
                    <div className="ml-1 w-full grow ring-transparent">
                      <CommentInput
                        value={values["text"]}
                        onChange={(value) => setFieldValue("text", value)}
                      />
                    </div>
                  </div>
                  <div className="mb-1 flex flex-row justify-end gap-x-2">
                    {showVisibility && (
                      <div
                        className={`flex flex-row items-center justify-center gap-x-1 text-xs ${
                          displayVisibility
                            ? "border-[1px] border-blue-900 bg-blue-900"
                            : "border-[1px] border-blue-900"
                        } w-32 cursor-pointer rounded-full py-1`}
                        onClick={() => setDisplayVisibility(!displayVisibility)}
                      >
                        <EyeIcon
                          className={`h-4 w-4 ${
                            displayVisibility
                              ? "fill-blue-900 text-white"
                              : "fill-white text-blue-900"
                          } `}
                        />
                        <div
                          className={`text-2xs ${
                            displayVisibility ? "text-white" : "text-blue-900"
                          }`}
                        >
                          {displayVisibility
                            ? "Hide Visibility"
                            : "Show Visibility"}
                        </div>
                      </div>
                    )}
                    <button
                      className={`btn-primary py-1 ${
                        values.text.trim().length === 0 && "disabled"
                      }`}
                      type="submit"
                      disabled={
                        !values.text ||
                        values.text.trim().length === 0 ||
                        isSubmitting
                      }
                    >
                      {isSubmitting ? (
                        <div className="flex flex-row items-center gap-x-2 text-white">
                          <div>
                            <Spinner className="text-blue h-4 w-4" />
                          </div>
                          <div className="">Posting</div>
                        </div>
                      ) : (
                        "Post"
                      )}
                    </button>
                  </div>
                  {displayVisibility ? (
                    <VisibilityField
                      visible={values.visibility}
                      hideLabel={hideVisibilityLabel}
                      visibilityOptions={[
                        { value: "public", label: "All users" },
                        { value: "groups", label: "Specific groups/users" },
                      ]}
                      defaultOptions={groups}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  } else {
    return <></>;
  }
}
